import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";

export const apiSlice = createApi({
  reducerPath: "api",
  baseQuery: async (args, api, extraOptions) => {
    const token = localStorage.getItem("token");

    // If the token doesn't exist, reject the request
    if (!token) {
      return;
    }

    const baseQueryFn = fetchBaseQuery({
      baseUrl: process.env.REACT_APP_BASE_URL || "http://47.41.243.35:4000",
      prepareHeaders: (headers) => {
        headers.set("Authorization", `Bearer ${token}`);
        return headers;
      },
    });

    return baseQueryFn(args, api, extraOptions);
  },
  // tagTypes: ['tour', 'stop', 'stop-custom-map'],
  endpoints: (builder) => ({}),
});

export const communityApiSlice = createApi({
  reducerPath: "api",
  baseQuery: fetchBaseQuery({
    baseUrl: process.env.REACT_APP_COMMUNITY_URL,
    prepareHeaders: (headers, { getState }) => {
      headers.set("Authorization", `Bearer ${localStorage.getItem("token")}`);
      return headers;
    },
  }),
  // tagTypes: ['tour', 'stop', 'stop-custom-map'],
  endpoints: (builder) => ({}),
});
